<!--suppress Stylelint -->
<script>
export default {
  page: {
    title: '',
  },
  components: {},
}
</script>

<template>
  <section
    :style="{
      backgroundImage: 'url(' + require('@assets/images/logo-bg.svg') + ')',
    }"
    :class="$style.welcomeSection"
    class="block block--hpbanner"
  >
    <div class="container">
      <div
        :class="$style.article"
        class="d-flex flex-column justify-content-center"
      >
        <div :class="$style.title">
          <h1 class="large-header">
            Une transition
            <span class="text-color-orange-dark"> digitale </span>
            réussie,<br />c’est un accompagnement sur mesure
          </h1>

          <div :class="$style.content">
            <p class="text-justify">
              En tant qu’agence digitale, Xenius vous propose des stratégies sur
              mesure en mettant à disposition les outils adéquats à votre
              métier.<br /><br />Nous avons créé des solutions en développant
              des logiciels et progiciels adaptés à vos besoins. Nos solutions
              restent simples et sont optimisées pour les secteurs de
              l’éducation et des assurances.
            </p>
            <!-- <button class="btn">
              <span class="circle bg-color-orange-dark"> </span>
              <span class="button-text">Ce que nous faisons</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import '@design';
.welcomeSection {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
.article {
  height: 100vh;
  min-height: 600px;
  .title {
  }
  .content {
    p {
      max-width: 30em;
    }
  }
}
</style>
