<script>
export default {
  data() {
    return {
      checked: 0,
      list: [
        "De quel service avez-vous besoin ?",
        "Audiovisuel",
        "Webmarketing",
        "Solution web & mobile",
        "Design graphique",
      ],
    };
  },
  watch: {
    checked: function (val) {
      console.log(val);
    },
  },
};
</script>

<template>
  <section
    :style="{
      backgroundImage:
        'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%), url(' +
        require('@assets/images/hourglass-bg.png') +
        ')',
    }"
    class="padder-50"
    :class="$style.devisSection"
  >
    <div class="container position-relative">
      <div
        :style="{
          backgroundImage: 'url(' + require('@assets/images/logo-fg.svg') + ')',
        }"
        :class="$style.bg"
      ></div>
      <div :class="$style.wrapper">
        <div class="row">
          <div class="col-md-6">
            <h3 class="text-color-red font-weight-bolder title-section">
              Devis en ligne
            </h3>
            <h2 class="font-weight-bolder title-section-desc">Un projet ?</h2>
            <p>
              Rien de plus simple ! Décrivez vos besoins en remplissant le formulaire suivant et recevez un devis gratuit dans un délai de 48 heures.
            </p>
          </div>
          <div class="col-md-6">
            <div class="dropdown">
              <button
                id="dropdownMenuButton"
                class="btn d-flex justify-content-between"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>
                  {{ list[checked] }}
                </span>
                <span class="devis-caret">
                  <font-awesome-icon :icon="['fas', 'caret-down']"></font-awesome-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label :class="{ checked: checked === '1' }" class="dropdown-item">
                  <input v-model="checked" type="radio" value="1" name="service" />
                  <span>Audiovisuel</span>
                </label>
                <label :class="{ checked: checked === '2' }" class="dropdown-item">
                  <input v-model="checked" type="radio" value="2" name="service" />
                  <span>Webmarketing</span>
                </label>
                <label :class="{ checked: checked === '3' }" class="dropdown-item">
                  <input v-model="checked" type="radio" value="3" name="service" />
                  <span>Solution web & mobile</span>
                </label>
                <label :class="{ checked: checked === '4' }" class="dropdown-item">
                  <input v-model="checked" type="radio" value="4" name="service" />
                  <span>Design graphique</span>
                </label>
              </div>
            </div>
            <button class="btn btn--smaller mt-5">
              <span class="circle bg-color-red"> </span>
              <span class="button-text">envoyer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import "@design";
.devisSection {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  .wrapper {
    padding: 150px 0;

    @include respond-below(sm) {
      padding: 0;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    transform: translateX(-30%);

    @include respond-below(sm) {
      display: none;
    }
  }
}
</style>
