<script>
export default {
  props: {
    name: String,
    description: String,
    info: String,
    avantage: String,
    avantages: Array,
    details: String,
    fonctionnalites: String,
    index: Number,
    path: String,
    color: String,
    hexaColor: String,
  },
  data: () => {
    return {
      border: [
        'border-bottom: 3px solid #761d75',
        'border-bottom: 3px solid #8eb637',
        'border-bottom: 3px solid #00a3df',
        'border-bottom: 3px solid #db0068',
        'border-bottom: 3px solid #fca026',
        'border-bottom: 3px solid #e4431b',
      ],
    }
  },
}
</script>

<template>
  <div
    :class="[$style.card]"
    :style="border[index]"
    class="card bg-dark text-white rounded-0 h-100"
  >
    <div
      class="card-body d-flex justify-content-between align-items-start flex-column"
    >
      <header>
        <p class="h4 font-weight-bold">{{ name }}</p>
        <hr :class="'bg-color-' + color" class="w-25 ml-0" />
        <p class="mt-4"  v-html="description"></p>
      </header>
      <footer>
        <a :href="'/solutions/' + path" class="btn btn--smaller mt-5">
          <span class="circle" :class="'bg-color-' + color"></span>
          <span class="button-text">{{ 'En savoir +' }}</span>
        </a>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.card {
  background-color: #111 !important;
  border-bottom: 3px solid #555;
  padding: 30px 15px 10px 15px;
}
</style>
