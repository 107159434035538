<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main.vue'
import Welcome from '@components/welcome-component.vue'
import ArticlesContainer from '@components/articles-container.vue'
import SolutionsContainer from '@components/solutions-container.vue'
import NousSommes from '@components/nous-sommes-component.vue'
import AProposComponent from '@components/a-propos-component.vue'
// import NotreBlogComponent from '@components/notre-blog-component.vue'
import DevisEnLigneComponent from '@components/devis-en-ligne-component.vue'
import NosPartenaires from '@components/nos-partenaires-component.vue'
export default {
  page: {
    title: 'Agence de communication en Tunisie et en France',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Welcome,
    ArticlesContainer,
    NousSommes,
    AProposComponent,
    SolutionsContainer,
    // NotreBlogComponent,
    DevisEnLigneComponent,
    NosPartenaires,
  },
}
</script>

<template>
  <Layout>
    <Welcome />
    <SolutionsContainer />
    <ArticlesContainer />
    <NousSommes />
    <AProposComponent />
    <!-- <NotreBlogComponent /> -->
    <DevisEnLigneComponent />
    <NosPartenaires />
  </Layout>
</template>
