<script>
import ArticleComponent from '@components/article-component.vue'
import { projectsComputed } from '@state/helpers'

export default {
  components: { ArticleComponent },
  computed: {
    ...projectsComputed,
  },
}
</script>

<template>
  <section :class="$style.sectionContainer" class="my-5">
    <div class="container">
      <h3 class="text-color-green font-weight-bolder title-section">
        Projets en vedette
      </h3>
      <!-- <h2 class="font-weight-bolder title-section-desc"></h2> -->
      <p class="paragraph-limit col-md-8 pl-0 text-justify">
        C’est à force d’expérience que Xenius s’est forgé un vaste réseau de
        clients satisfaits et fidèles. Nous avons tissé des liens forts avec de
        grandes entreprises opérant dans divers secteurs d’activité en France
        comme à l’étranger.
      </p>
      <p class="paragraph-limit mb-5">
        Souhaitez-vous nous confier un projet ?
      </p>
      <div :class="$style.projectPortfolio">
        <div class="row">
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
            <ArticleComponent v-bind="projects[0]" />
          </div>
          <div
            class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0"
            :class="$style.projectPortfolioTop"
          >
            <ArticleComponent v-bind="projects[1]" />
          </div>
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
            <ArticleComponent v-bind="projects[2]" />
          </div>
          <div
            class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0"
            :class="$style.projectPortfolioTop"
          >
            <ArticleComponent v-bind="projects[3]" />
          </div>
        </div>
      </div>
      <div :class="$style.jobs" class="text-center text-uppercase jobs">
        <a href="/projets" class="link-underline">
          <b>Voir toutes nos réalisations</b>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import '@design';
.sectionContainer {
  z-index: 1;
}
.jobs {
  margin: 2rem 0 3rem;
  a,
  a:visited {
    font-size: $size-font-small;
    color: white;
  }
}
.projectPortfolio {
  .projectPortfolioTop {
    margin-top: 3rem;

    @include respond-below(lg) {
      margin-top: 0;
    }
  }
}
</style>
