<script>
export default {
  created() {
    this.activities = [
      { text: "Design graphique +", icon: "ico-icon-1" },
      { text: "Audiovisuel +", icon: "ico-icon-2" },
      { text: "Webmarketing +", icon: "ico-icon-3" },
      { text: "Solutions web & mobile +", icon: "ico-icon-4" },
      { text: "Maintenance & webmastering +", icon: "ico-icon-5" },
      { text: "Stratégie de contenu +", icon: "ico-icon-6" },
    ];
  },
};
</script>

<template>
  <section id="about" class="padder-50">
    <div :class="$style.sectionContainer">
      <div
        :class="$style.bg"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 95%, rgba(0,0,0,1) 100%), url(' +
            require('@assets/images/logo-bg.svg') +
            ')',
        }"
      ></div>
      <div class="container position-relative">
        <div class="row">
          <div :class="$style.nousSommesBlock" class="col-lg-6">
            <div :class="$style.nousSommesBlockPadding">
              <!-- <h3 class="font-weight-bolder text-uppercase title-section"
              >Notre mission</h3 -->
              <h2 class="font-weight-bolder title-section-desc">Notre mission</h2>
              <p>
                Xenius a pour objectif d’aider les entreprises à adopter les tendances digitales. Nos équipes mettent à votre disposition une palette complète de services afin de répondre à toutes vos attentes.
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div :class="$style.nousSommesList">
              <ul>
                <li
                  v-for="activity in activities"
                  :key="activity.text"
                  :class="$style.activity"
                >
                  <i :class="activity.icon"></i>
                  <span>{{ activity.text }}</span>
                </li>
              </ul>
            </div>
            <!-- <button class="btn">
            <span class="circle bg-color-blue"> </span>
            <span class="button-text">Ce que nous faisons</span>
          </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import "@design";
.sectionContainer {
  position: relative;

  @include respond-below(md) {
    overflow: hidden;
  }
  &::before {
    content: "";

    @include respond-above(md) {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: $color-theme-blue;
    }
  }
  .bg {
    position: absolute;
    top: -50%;
    right: 0;
    z-index: -1;
    width: 50%;
    height: 200%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }
}
.nousSommesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .nousSommesBlockPadding {
    @include respond-below(md) {
      padding: 3rem 0;
    }
  }

  @include respond-below(md) {
    margin-bottom: 3rem;
  }
  &::before {
    content: "";

    @include respond-below(md) {
      position: absolute;
      top: 0;
      left: -50%;
      z-index: -1;
      width: 200%;
      height: 100%;
      background: $color-theme-blue;
    }
  }
}
.nousSommesList {
  ul {
    @include respond-below(md) {
      padding: 0;
    }
  }
  .activity {
    position: relative;
    padding-left: 50px;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: bold;
    list-style: none;

    @include respond-below(xs) {
      font-size: 16px;
    }
    [class^="ico-"] {
      position: absolute;
      top: -2px;
      left: 0;
      font-size: 27px;
    }
  }
}
</style>
