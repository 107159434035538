<script>
import SolutionComponent from '@components/solution-component.vue'
import { projetDoneComputed } from '@state/helpers'

export default {
  components: { SolutionComponent },
  computed: {
    ...projetDoneComputed,
  },
}
</script>

<template>
  <section :class="$style.sectionContainer" class="my-5">
    <div class="container">
      <h3 class="text-color-orange font-weight-bolder title-section"
        >Nos solutions</h3
      >

      <!-- <h2>Lorem ipsum</h2> -->
      <p class="paragraph-limit col-md-8 pl-0 text-justify">
        Notre agence digitale vous propose des solutions adaptées à vos besoins.
        Nous avons développé des solutions progiciels et logiciels qui sauront
        répondre à vos attentes. Contrairement à un logiciel classique qui
        répond à un besoin particulier, nos progiciels rassembleront tous les
        programmes et logiciels nécessaires au bon fonctionnement de votre
        projet digital.
        <br /><br />Nous proposons des solutions simples et optimisées pour les
        secteurs de l’éducation et des assurances.
      </p>
      <div class="row">
        <div
          v-for="(item, index) in projetDone"
          :key="item.name"
          class="col-sm-6"
          style="padding-top: 2rem"
        >
          <SolutionComponent v-bind="projetDone[index]" :index="index" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import '@design';

.sectionContainer {
  z-index: 1;
}

.card {
  background-color: #111 !important;
  border-bottom: 3px solid #555;
  padding: 30px 15px 10px 15px;
}

.hrRed {
  border-top: 1px solid #e4431b;
}

.hrPurple {
  border-top: 1px solid #761d75;
}

.hrGreen {
  border-top: 1px solid #8eb637;
}

.hrPink {
  border-top: 1px solid #db0068;
}

.hrOrange {
  border-top: 1px solid #fca026;
}

.hrBlue {
  border-top: 1px solid #00a3df;
}

.borderRed {
  border-bottom: 3px solid #e4431b;
}

.borderPink {
  border-bottom: 3px solid #db0068;
}

.borderOrange {
  border-bottom: 3px solid #fca026;
}

.borderBlue {
  border-bottom: 3px solid #00a3df;
}

.borderPurple {
  border-bottom: 3px solid #761d75;
}

.borderGreen {
  border-bottom: 3px solid #8eb637;
}
</style>
